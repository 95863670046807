import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useGetClientesDocsCompletosQuery } from '../../libs/redux/slices/Repoprog/RepoprogApiSlice';

const Repoprog = ({ selectedClient, setSelectedClient }) => {
  const [filter, setFilter] = useState('');
  const { data: response, isFetching } = useGetClientesDocsCompletosQuery(filter);  // Pasamos el filtro directamente
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (response?.data) {
      const clients = response.data.map((sCliente) => ({
        label: sCliente.CLINOMXX,
        value: sCliente.CLIIDXXX,
      }));
      setOptions(clients);
    }
  }, [response]);

  const handleInputChange = (inputValue) => {
    setFilter(inputValue);  // Actualizamos el filtro
  };

  return (
    <Select
      options={options}
      onInputChange={handleInputChange}
      onChange={(selectedOption) => setSelectedClient(selectedOption.value)}
      value={options.find(option => option.value === selectedClient)}
      isLoading={isFetching}
      placeholder="SELECCIONE"
    />
  );
};

export default Repoprog;
